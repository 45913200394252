import React, { useState } from 'react';
import styles from '../../styles/iconcard.module.scss';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import { Button, Modal, Row, Col } from 'react-bootstrap';

const IconCard = ({ cardData }) => {
  const [show, setShow] = useState(false);
  const { logo, linkUrl, openModal, moreLinks, text, height } = cardData;

  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  return (
    <>
      <div
        className={`px-3 px-md-4 px-lg-5 px-xl-6 px-xxl-7 py-3 py-md-5 py-lg-7 py-xl-8 py-xxl-9 rounded-4 border border-primary-subtle shadow d-flex align-items-center justify-content-center h-100 position-relative ${styles.amp_icon_card} ${(linkUrl === '' || linkUrl === null) && `pe-none ${styles.disabled}`}`}
      >
        {/* <ReactSVG src={logo} className={`${styles.amp_card_logo_wrap}`} /> */}
        {/* { logo && <ReactSVG src={logo} className={`${styles.amp_card_logo_wrap}`} /> }  */}
        { logo && <img src={logo} alt="" className={`${styles.amp_card_logo_wrap}`} style={{height: height}} /> } <br></br>
        { text && <h3 className='h6 mb-0 px-2'>{text}</h3> }
        <div>
            <Link to={linkUrl} target='_blank' className="stretched-link"></Link>
        </div>
      </div>

      {openModal &&
        <>
          {/* modal start */}
          <Modal show={show} onHide={handleClose} size='xl' centered scrollable backdrop="static">
            <Modal.Body className='px-md-4 pb-md-4 px-lg-5 pb-lg-5 px-xl-6 pb-xl-6 pt-0'>
              <div className='amp_modal_close_wrap text-end mx-n3 mx-md-n4 mx-lg-n5 mx-xl-n6 bg-white z-1'>
                <Button variant="danger" onClick={handleClose} className='amp_modal_close'>
                  <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/close.svg`} />
                </Button>
              </div>
              <div className='d-flex flex-column row-gap-6'>
                {moreLinks?.map((item, index) => (
                  <>
                    <div key={index}>
                      <h3 className='h5 text-primary'>{item.title}</h3>
                      <Row className='row-gap-3'>
                        {item.linkUrls?.map((innerItem, innerIndex) => (
                          <Col xs={12} md={6} lg={3} key={innerIndex}>
                            <div
                              className={`p-2 p-md-3 rounded-4 border border-light shadow d-flex flex-column gap-2 h-100 position-relative ${styles.amp_icon_card} ${(innerItem.linkUrl === '' || innerItem.linkUrl === null) && `pe-none ${styles.disabled}`}`}
                            >
                              <h4 className='h6 mb-0'>{innerItem.linkText}</h4>
                              <Link to={innerItem.linkUrl} target='_blank' className="fs-7 fw-semibold d-inline-flex align-items-center gap-2 stretched-link">
                                {innerItem.linkText} <ReactSVG src={`${process.env.PUBLIC_URL}/assets/images/icons/right_arrow.svg`} />
                              </Link>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </div>
                  </>
                ))}
              </div>
            </Modal.Body>
          </Modal >
          {/* modal end */}
        </>
      }
    </>
  );
};

export default IconCard;
